.welcome_content {

	@include media-breakpoint-down(sm) {
		.list {
			display: inline;
		}
	}

		.lead {
			@include make-flex;
			flex-wrap: nowrap;
			align-items: center;
			//text-align: left;
		}
		.img-lead-link {
			min-width: 110px;
			margin: 0 0 0 10px;
		}

}


.img-lead-link {
	display: block;
	width: 110px;
	height: auto;
}

	.img-lead {
		display: block;
		width: 100%;
	}
#top { outline: none !important; }

.banner-top {
	padding: 10px 10px 0;
	@include media-breakpoint-up(md) {
		padding: 0;
		background-color: theme(primary, base);
		.container { height: 5px; }
	}
	@include media-breakpoint-up(md) {
		background: linear-gradient(to bottom, theme(primary, base) 98%, $white 98%);
		overflow: hidden;
		.container {
			overflow: hidden;
			height: 39px;
			background: url("/_/images/layout/cityscape.svg") center 18px no-repeat;
			background-size: 100%;
			background-position: center bottom;
		}
	}
	@include media-breakpoint-up(lg) {
		.container { height: 95px; }
	}
	@include media-breakpoint-up(xl) {
		.container { height: 90px; }
	}
}

.banner {

	padding: 15px;
	text-align: center;
	line-height: 1em;

	@include media-breakpoint-only(md) {
		padding-bottom: 15px;
		.banner_branding { order: 1; @include make-col(4); }
			.logo-banner { margin-top: 15px; }
		.banner_hours { order: 2; @include make-col(8); text-align: right; align-self: flex-start; }
		.banner_contact { order: 3; @include make-col(8); margin: -130px 0 0 auto; text-align: right; }
		.banner_visit { order: 4; @include make-col(8); margin: -40px 0 0 auto; text-align: right; }
		.banner_nav { margin-top: 15px; order: 5; }
		.list-hours.open { right: 0; }
	}

	@include media-breakpoint-up(lg) {
		padding-bottom: 0;
		margin-top: -100px;
		padding-bottom: 35px;
		.banner_hours { order: 1; @include make-col(4); text-align: left; }
		.banner_contact { order: 2; @include make-col(4); text-align: center; }
		.banner_visit { order: 3; @include make-col(4); text-align: right; }
		.banner_hours,
		.banner_contact,
		.banner_visit { color: $white; font-weight: $body-weight-bold; }
		.banner_branding {
			order: 4;
			@include make-col(3);
			padding-top: 80px;
			.logo-banner { margin: 0; }
		}
		.banner_nav {
			order: 5;
			@include make-col(9);
			margin-top: 0; 
			padding-top: 80px;
			.banner-nav { text-align: right; }
		}
	}

	@include media-breakpoint-up(xl) {
		.banner_visit { padding: 0;	}
		.banner_branding { padding-top: 80px; }
		.banner_nav { padding-top: 90px; }
	}

}

	.banner_visit {
		@include make-col(6);
		@include font-size(14);

		a {
			display: inline-block;
			padding: 2px 5px;
			position: relative;
			text-decoration: none;
			&:hover {
				.address_overlay {
					@include transition(opacity ease-in-out 200ms);
					opacity: 1;
				}
			}
			.address_overlay {
				position: absolute;
				@include transition(opacity ease-in-out 200ms);
				@include make-flex;
				align-items: center;
				justify-content: center;
				top: 0; right: 0; bottom: 0; left: 0;
				width: 100%;
				border-radius: 4px;
				background-color: theme(secondary, base);
				color: $white;
				opacity: 0;
				b {
					@include font-size(15);
					font-family: $font-family-headings;
					text-transform: uppercase;
				}
			}
		}
		
		@include media-breakpoint-up(lg) {
			a { color: $white; }
		}

		@include media-breakpoint-up(xl) {
			br { display: none; }
			.street:after { content: ", "; }
		}

	}

	.banner_contact {
		@include make-col(6);
	}

	.banner_hours {
		margin-top: 15px;
		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}

		.visit-address { margin-bottom: 0; }


	.banner_contact {
		@include media-breakpoint-up(lg) {
			border-left: 2px solid $white;
			border-right: 2px solid $white;
		}
		@include media-breakpoint-up(xl) {
			text-align: center;
			.contact-call {
				@include make-flex;
				align-items: center;
				justify-content: center;
				.contact-phone, .contact-note {

					padding: 0 6px;
				}
				.phone { order: 2; }
				.contact-note { order: 1; }
			}
		}
	}


	.banner_nav {
		@include media-breakpoint-up(lg) {
			display: flex;
			flex-wrap: wrap;
			.nav { order: 2; width: 100%; }
			.tod {
				order: 1;
				width: 100%;
				margin: 0 0 8px;
				text-align: right;
				.btn {
					padding: 8px 10px;
				}
			}
		}
	}

	.tod {
		margin-top: 15px;
		> * { vertical-align: middle; }
		a, img { display: inline-block !important; }
		a+a { margin-left: 15px; }
	}
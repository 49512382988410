.site_info {
	position: relative;
	padding: 30px 0;
	text-align: center;
	border-top: 3px solid $white;
	background-color: theme(highlight, base);
	color: $white;

}

.info_company {

	@include media-breakpoint-up(sm) {

		@include make-flex;
		align-items: center;
		justify-content: center;
		.address,
		.call {
			width: auto;
			margin: 0;
		}
		.call { padding-left: 20px; }
	}

	@include media-breakpoint-up(lg) {
		justify-content: flex-start;
		text-align: left;
	}	

}

.info_copyright {

	@include font-size(14);
	line-height: 1.3em;
	text-align: center; 

	.copyright-list { @include list_unstyled; }

	a {
		font-weight: $body-weight-bold;
		line-height: 1em;
		text-decoration: none;
		color: $white;
	}

	@include media-breakpoint-up(sm) {
		.copyright-list {
			@include list_inline;
			li:not(:last-child) { margin-right: 15px; }
		}
	}

	@include media-breakpoint-up(md) { padding-top: 15px; }

	@include media-breakpoint-up(lg) {
		padding: 0;
		text-align: right;
		.copyright-list {
			li {
				display: block !important;
				margin: 0 !important;
			}
		}		
	}

}


.page_front .site_info {

	@include media-breakpoint-up(sm) {
		.copyright-list {
			@include list_inline;
			li:not(:last-child) { margin-right: 15px; }
		}
	}

	@include media-breakpoint-up(md) {
		.info_copyright {
			padding-top: 0;
			@include make-col(12);
		}
	}

	@include media-breakpoint-up(lg) {
		.info_copyright { 	
			@include make-col(12);
			padding-top: 0;
			text-align: center;
			.copyright-list {
				li {
					display: inline-block !important;
					&:not(:last-child) { margin-right: 15px !important; }
				}
			}		
		}
	}

}
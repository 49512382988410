.sidebar {

	padding-top: 30px;

	.form_quick { margin-bottom: 20px; }

	.flip & {
		padding-top: 0;
		padding-bottom: 30px;
	}

	@include media-breakpoint-up(md) {
		padding-top: 0;
	}

}

.page_take_out .sidebar {

	@include media-breakpoint-only(md) {
		.widget_wrapper {
			@include make-flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			> div { width: 49%; }
		}
	}

	.pbs {
		vertical-align: middle;
		> * { vertical-align: middle; }
	}

}
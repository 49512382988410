.block {
	padding: 45px 15px;
	&:not(.content, .block_content, .page_title) .row {
		align-items: center;
		justify-content: center;
	}
}


	.page_take_out .row,
	.block.block_menu.content .row {
		align-items: flex-start;
	}

.block_title {
	margin-bottom: 30px;
	text-align: center;
	line-height: 1em;
}

	.block-title {
		@extend %h2;
		margin: 0;
	}

.block_content {
	> *:last-child { margin-bottom: 0; }
}

.block_more {
	margin-top: 15px;
}
.call {
	@include font-size(15);
	line-height: 1em;
	.phone {
		display: inline-block;
		@include font-size(22);
		font-weight: $body-weight-bolder;
		line-height: 1em;
		letter-spacing: -0.04em;
		&.company-phone { color: $white; }
		&.contact-phone { color: theme(secondary, base); }
	}

	.contact-note {
		display: block;
		font-size: 95%;
	}

}



.slider_jumbo {
    
	padding: 0;

    &:hover {
        .slick-prev,
        .slick-next { opacity: 1; }
    }

	.slick-prev,
    .slick-next {
        display: none !important;
        width: 30px;
        background-size: 10px;
        opacity: 1;
        &:hover {
            background-size: 14px;
            opacity: 1;
        }
    }

        .slick-prev { background-image: url("/_/images/icons/slide-white-prev.png"); }
        .slick-next { background-image: url("/_/images/icons/slide-white-next.png"); }


    .slide_caption {
        line-height: 1em;
        padding: 15px;
        text-shadow: none;
        border: 3px solid $white;
        border-top: none;
        background-color: theme(text, dark);
        color: $white;
    }

        .caption-title {
            margin: 0;
            @include font-size(17);
            font-weight: $heading-weight-bolder;
            line-height: 1em;
            .price {
                display: block;
                margin-top: 3px;
                color: theme(success, base);
            }
        }


	.slide {
		padding: 0 5px;
	}

	.slide-placeholder {
		border: 3px solid $white;
	}

    @include media-breakpoint-up(md) {
        padding: 0 30px;
        .slick-prev,
        .slick-next { display: block !important; }
    }

    @include media-breakpoint-up(lg) {
        .slide_wrap { position: relative; }
        .slide_caption {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: linear-gradient(to right, rgba(black, 0.7) 62.5%, rgba(black, 0.95));
        }
        .caption-title {
            @include font-size(19);
            .price {
                display: inline-block;
                margin: 0;
                float: right;
            }
        }
    }

}
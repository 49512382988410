.block_jumbo {
	padding: 60px 15px 30px;
	
	border-bottom: 4px solid $white;
	box-shadow: 0 4px 0 0 theme(highlight, base);

	background: url("/_/images/layout/ribbon_top.png"),
				url("/_/images/layout/sunburst.svg"),
				theme(secondary, base);
	background-repeat: repeat-x, repeat, repeat;
	background-position: top center, center, center;
	background-size: auto, cover, cover; 

}

	.jumbo_marketing {
		padding: 0;
		@include media-breakpoint-up(md) {
			padding: 0 10px;
		}
	}

	.jumbo_alert_wrapper {
		margin-top: 15px;
		padding: 0 5px;
		width: 100%;

		.alert {
			.alert-title {
				margin-bottom: 5px;
				@include font-size(18);
				font-weight: $heading-weight-bolder;
			}
			@include font-size(15);
			line-height: 1em;
		}

		.alert-facebook {
			@include alert(#4267B2, $white);
		}

		@include media-breakpoint-up(md) {
			padding: 0 45px;

			.alert_text {
				@include make-flex;
				align-items: center;
				h4, p {
					float: left;
					width: 50%;
					margin: 0 !important;
					line-height: 1em;
				}
				p { padding-left: 15px; }
			}

		}

	}
.block_socials {
	padding: 0 15px 20px;
	@include media-breakpoint-up(lg) {
		padding: 0 15px 15px;
	}
	@include media-breakpoint-down(sm) {
		.social-item:nth-of-type(1),
		.social-item:nth-of-type(2) {
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}
}

	.social_content {
		font-weight: $body-weight-bold;
		font-style: italic;
		letter-spacing: -0.0275em;
		text-align: center;
	}

		.social-item {
			display: inline-block;
			width: 120px;
			margin-left: 15px;
			line-height: 1em;
			vertical-align: middle;
			&.--first { margin-right: 0; }
			img {
				display: block;
				width: 100%;
				margin: 0 auto;
			}
		}